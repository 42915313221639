<template>
  <section class="login-register">
    <div class="form">
      <div class="btn__login-register">
        <router-link
          :to="{ name: 'login' }"
          active-class="active"
          class="btn-login"
          tag="button"
        >
          {{ $t("login.title") }}
        </router-link>
        <router-link
          :to="{ name: 'register' }"
          active-class="active"
          class="btn-register"
          tag="button"
        >
          {{ $t("register.title") }}
        </router-link>
      </div>
      <div :class="['input-form', { invalide: errors.first_name }]">
        <span>{{ $t("register.first_name") }}</span>
        <input
          v-model="form.first_name"
          :placeholder="$t('register.enter_your_first_name')"
          type="text"
        />
        <span v-if="errors.first_name" class="invalide_text">
          {{ errors.first_name[0] }}
        </span>
      </div>
      <div :class="['input-form', { invalide: errors.last_name }]">
        <span>{{ $t("register.last_name") }}</span>
        <input
          v-model="form.last_name"
          :placeholder="$t('register.enter_your_last_name')"
          type="text"
        />
        <span v-if="errors.last_name" class="invalide_text">
          {{ errors.last_name[0] }}
        </span>
      </div>
      <div :class="['input-form', { invalide: errors.email }]">
        <span>{{ $t("login.email") }}</span>
        <input
          v-model="form.email"
          :placeholder="$t('login.enter_your_email')"
          type="text"
        />
        <span v-if="errors.email" class="invalide_text">
          {{ errors.email[0] }}
        </span>
      </div>
      <div :class="['input-form', 'number', { invalide: errors.phone }]">
        <span>{{ $t("register.phone") }}</span>
        <input
          id="input-number"
          v-model="form.phone"
          :placeholder="$t('register.enter_your_phone')"
          type="number"
        />
        <span v-if="errors.phone" class="invalide_text">
          {{ errors.phone[0] }}
        </span>
        <PhoneCountrySelect :country-code.sync="country_code" />
      </div>
      <div
        id="input-pass"
        :class="['input-form', { invalide: errors.password }]"
      >
        <span>{{ $t("login.password") }}</span>
        <input
          v-model="form.password"
          :placeholder="$t('login.enter_your_password')"
          type="password"
        />
        <span v-if="errors.password" class="invalide_text">
          {{ errors.password[0] }}
        </span>
      </div>
      <div :class="['input-form', { invalide: errors.password }]">
        <span>{{ $t("register.confirm_password") }}</span>
        <input
          v-model="form.password_confirmation"
          :placeholder="$t('register.enter_your_password_again')"
          type="password"
        />
        <span v-if="errors.password" class="invalide_text">
          {{ errors.password[0] }}
        </span>
      </div>

      <div class="input-form">
        <input :value="$t('sign_up')" type="submit" @click="register" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import PhoneCountrySelect from "@/components/PhoneCountrySelect";

export default {
  components: {
    PhoneCountrySelect
  },

  data() {
    return {
      country_code: "+33",
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: ""
      },
      errors: {}
    };
  },

  methods: {
    ...mapActions("auth", ["signUp"]),
    async register() {
      this.errors = [];
      const data = Object.assign({}, this.form, {
        phone: this.country_code + this.form.phone
      });

      await this.signUp(data)
        .then(() => {
          this.$router.replace({ name: "verify" });
        })
        .catch(error => (this.errors = error.response.data.errors));
    },
    getClass(field) {
      return ["input_block", { error: field !== undefined }];
    }
  },

  metaInfo() {
    return {
      title: this.$t("register.title")
    };
  }
};
</script>
