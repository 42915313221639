<template>
  <div>
    <div v-if="message !== ''" id="modal__sorry" class="modal_home">
      <div class="content_modal_home">
        <button class="close__modal" @click="message = ''"></button>
        <div class="warring_text">
          {{ message }}
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="message = ''">
            OK
          </button>
        </div>
      </div>
    </div>
    <section class="login-register">
      <div class="form">
        <div class="btn__login-register">
          <router-link
            :to="{ name: 'login' }"
            active-class="active"
            class="btn-login"
            tag="button"
          >
            {{ $t("login.title") }}
          </router-link>
          <router-link
            :to="{ name: 'register' }"
            active-class="active"
            class="btn-register"
            tag="button"
          >
            {{ $t("register.title") }}
          </router-link>
        </div>
        <div :class="['input-form', { invalide: errors.email }]">
          <span>{{ $t("login.email") }}</span>
          <input
            v-model="form.email"
            :placeholder="$t('login.email')"
            type="email"
          />
          <span v-if="errors.email" class="invalide_text">
            {{ errors.email[0] }}
          </span>
        </div>
        <div :class="['input-form', { invalide: errors.password }]">
          <span>{{ $t("login.password") }}</span>
          <input
            v-model="form.password"
            :placeholder="$t('login.password')"
            type="password"
          />
          <span v-if="errors.password" class="invalide_text">
            {{ errors.password[0] }}
          </span>
        </div>
        <a class="forget" href="#" @click="sendForgotPassword">
          {{ $t("login.forgot_password") }}?
        </a>
        <div class="input-form">
          <input :value="$t('sign_in')" type="submit" @click="login" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      message: "",
      errors: {}
    };
  },

  mounted() {
    document.body.classList.remove("page");
  },

  destroyed() {
    document.body.classList.add("page");
  },

  methods: {
    ...mapActions("auth", ["signIn", "forgotPassword"]),
    async login() {
      this.errors = [];

      await this.signIn(this.form)
        .then(() => {
          this.$router.replace(
            this.$route.query.from
              ? { path: this.$route.query.from }
              : { name: "cart" }
          );
        })
        .catch(error => (this.errors = error.response.data.errors));
    },
    async sendForgotPassword() {
      await this.forgotPassword(this.form.email)
        .then(response => {
          this.message = response.data.message;
          this.form.email = "";
          this.form.password = "";
          this.errors = [];
        })
        .catch(error => (this.errors = error.response.data.errors));
    },
    getClass(field) {
      return ["input_block", { error: field !== undefined }];
    }
  },

  metaInfo() {
    return {
      title: this.$t("login.title")
    };
  }
};
</script>
