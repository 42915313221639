<template>
  <section class="login-register">
    <div class="form">
      <div :class="['input-form', { invalide: errors.password }]">
        <span>{{ $t("reset.password") }}</span>
        <input
          v-model="form.password"
          :placeholder="$t('reset.enter_your_new_password')"
          type="password"
        />
        <span v-if="errors.password" class="invalide_text">
          {{ errors.password[0] }}
        </span>
      </div>
      <div :class="['input-form', { invalide: errors.password_confirmation }]">
        <span>{{ $t("reset.password_confirmation") }}</span>
        <input
          v-model="form.password_confirmation"
          :placeholder="$t('reset.enter_your_new_password_again')"
          type="password"
        />
        <span v-if="errors.password_confirmation" class="invalide_text">
          {{ errors.password_confirmation[0] }}
        </span>
      </div>
      <div class="input-form">
        <input
          :value="$t('reset.title')"
          type="submit"
          @click="sendResetPassword"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
        token: this.$route.params.token,
        email: this.$route.query.email
      },
      errors: []
    };
  },

  methods: {
    ...mapActions("auth", ["resetPassword"]),
    async sendResetPassword() {
      await this.resetPassword(this.form)
        .then(() => {
          this.$router.replace({ name: "home" });
        })
        .catch(error => (this.errors = error.response.data.errors));
    }
  },

  metaInfo() {
    return {
      title: this.$t("reset.title")
    };
  }
};
</script>
