<template>
  <div id="mdl_adress" class="modal_adress">
    <div class="content_modal_adresss">
      <button class="close__modal" @click="$emit('close-popup')"></button>
      <h3 class="title__adress">
        {{ $t("verification.change_phone") }}
      </h3>
      <div :class="['input-form', 'number', { invalide: errors.phone }]">
        <span>{{ $t("register.phone") }}</span>
        <input
          id="input-number"
          v-model="form.phone"
          :placeholder="$t('register.enter_your_phone')"
          type="text"
        />
        <span v-if="errors.phone" class="invalide_text">
          {{ errors.phone[0] }}
        </span>
        <PhoneCountrySelect :country-code.sync="country_code" />
      </div>
      <div class="btn_adress">
        <button id="btn-pocket" class="nav__btn active" @click="save">
          {{ $t("buttons.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PhoneCountrySelect from "@/components/PhoneCountrySelect";

export default {
  components: {
    PhoneCountrySelect
  },

  data() {
    return {
      errors: {},
      country_code: "+33",
      form: {
        phone: ""
      }
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    ...mapActions("auth", ["me", "resendCode"]),
    save() {
      this.errors = [];

      const data = Object.assign({}, this.form, {
        phone: this.country_code + this.form.phone
      });
      this.$http
        .patch("/api/user", data)
        .then(async () => {
          await this.me();
          this.$emit("close-popup");
        })
        .catch(error => (this.errors = error.response.data.errors));
    }
  }
};
</script>
