<template>
  <div>
    <ChangePhonePopup v-show="isChangePhoneOpened" @close-popup="togglePopup" />
    <section class="login-register">
      <div class="form">
        <div class="code__info">
          {{ $t("verification.code_sent") }}
          <div class="phone" @click="togglePopup">{{ user.phone }}</div>
        </div>
        <div :class="['input-form', { invalide: errors.code }]">
          <span>{{ $t("verification.code") }}</span>
          <input
            v-model="form.code"
            :placeholder="$t('verification.enter_the_code')"
            type="number"
          />
          <span v-if="errors.code" class="invalide_text">
            {{ errors.code[0] }}
          </span>
        </div>
        <a class="forget" href="#" @click="resend">
          {{ $t("verification.resend") }}?
        </a>
        <div class="input-form">
          <input
            :value="$t('verification.verify')"
            type="submit"
            @click="verify"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ChangePhonePopup from "@/components/ChangePhonePopup";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ChangePhonePopup
  },

  data() {
    return {
      isChangePhoneOpened: false,
      form: {
        code: ""
      },
      errors: []
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    ...mapActions("auth", ["verifyCode", "resendCode"]),
    verify() {
      this.verifyCode(this.form)
        .then(() => {
          this.$router.replace({ path: this.$route.query.from || "/" });
        })
        .catch(error => (this.errors = error.response.data.errors));
    },
    resend() {
      this.resendCode().catch(error => {
        this.errors = error.response.data.errors;
      });
    },
    togglePopup() {
      this.isChangePhoneOpened = !this.isChangePhoneOpened;
    }
  },

  metaInfo() {
    return {
      title: this.$t("verification.title")
    };
  }
};
</script>

<style scoped>
.code__info {
  display: flex;
  margin-top: 25px;
  font-size: 17px;
  color: #707070;
}

.code__info > .phone {
  border-bottom: 1px dashed #174040;
  cursor: pointer;
  margin-left: 5px;
}
</style>
